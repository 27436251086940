import React from 'react'
import Footer from '../components/Footer'
import Navigation from '../components/Navigation'
import Contact from '../components/Contact'

function Contat() {
  return (
<>
<Navigation/>
<Contact/>
<Footer/>
</>
  )
}

export default Contat