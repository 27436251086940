import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import axios from 'axios';
import Footer from '../components/Footer';
import { DNA } from 'react-loader-spinner';
import { useLocationDetection } from '../utils/hooks/useLocationDetection';
import Hero from '../components/Hero';
import Offer from '../components/Offer';
import Contact from '../components/Contact';


const Home=()=> {

  return (
    <div className="home">
      <Navigation/>
      <Hero/>
      <Offer/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default Home;