import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navigation=()=> {
    const currentLocation = useLocation();
  const isActive = (path) => currentLocation.pathname === path ? 'active' : '';
  return (
<header class="header">
        <div class="container-no">
            <div class="row1">
                <div class="col-12">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <div class="container">
                            <Link class="navbar-brand" href="/">
                                <h2 class="m-0">
                                    bookrestort
                                </h2>
                            </Link>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
                            </button>

                            <div class="social-links">
                                <a href="https://twitter.com/?lang=en" target="_blank"><i class="fa fa-twitter"></i></a>
                                <a href="https://www.facebook.com/" target="_blank"><i class="fa fa-facebook-f"></i></a>
                                <a href="https://www.instagram.com/?hl=en" target="_blank"><i class="fa fa-instagram"></i> </a>
                                <a href="https://www.youtube.com/" target="_blank"><i class="fa fa-youtube"></i></a>
                            </div>

                        </div>
                        <div class="collapse navbar-collapse" id="navbarScroll">
                        <div className="container">
            <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                    <Link className={`nav-link ${isActive('/')}`} to="/">Welcome</Link>
                </li>
                <li className="nav-item">
                    <Link className={`nav-link ${isActive('/art-hotels')}`} to="/art-hotels">Art Hotels</Link>
                </li>
                <li className="nav-item">
                    <Link className={`nav-link ${isActive('/capsule-hotels')}`} to="/capsule-hotels">Capsule Hotels</Link>
                </li>
                <li className="nav-item">
                    <Link className={`nav-link ${isActive('/casino-hotels')}`} to="/casino-hotels">Casino Hotels</Link>
                </li>
               
                <li className="nav-item">
                    <Link className={`nav-link ${isActive('/about-us')}`} to="/about-us">About Us</Link>
                </li>
                <li className="nav-item">
                    <Link className={`nav-link ${isActive('/contact-us')}`} to="/contact-us">Contact us</Link>
                </li>
            </ul>
        </div>


                        </div>

                    </nav>

                </div>
            </div>
        </div>
    </header>
  );
}

export default Navigation;