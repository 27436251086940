import React from 'react'
import Footer from '../components/Footer'
import Navigation from '../components/Navigation'

function About() {
  return (
   <>
    <Navigation/>
  
   
  <main id="hotels" class="min-height">
          <section class="section hero pb-4">
              <div class="container">
                  <div class="row">
                      <div class="col-md-12">
                          <h1 class="top-heading text-center">About bookrestort</h1>
                      </div>
                  </div>
              </div>
          </section>
  
  
  
          <section class="section zigzag-section">
              <div class="container">
                  <div class="row">
                      <div class="col-md-12">
                          <div class="fill-text1">
  
                              <p><span style={{ fontWeight: 400 }}>Welcome to </span><strong>bookrestort </strong><span style={{ fontWeight: 400 }}>, your trusted guide to discovering the best hotels in
                                      Australia. Whether you’re an art-loving traveler, a luxury enthusiast, an adventurer
                                      seeking unique experiences, or simply looking for a convenient and modern option,
                                      our site is designed to provide you with honest and detailed reviews of a selection
                                      of unique hotels across the country.</span></p>
                              <h4><strong>Our Mission</strong></h4>
                              <p><span style={{ fontWeight: 400 }}>At bookrestort, our mission is simple: to help you find
                                      the perfect hotel for your next stay. We know that every traveler is unique, which
                                      is why we highlight four types of hotels that stand out for their character and
                                      originality: Art Hotels, Capsule Hotels, Casino Hotels, and Ice Hotels. Our goal is
                                      to provide you with all the information you need to make the best choice based on
                                      your preferences and needs.</span></p>
                              <h4><strong>Why Choose Us?</strong></h4>
                              <ul>
                                  <li style={{ fontWeight: 400 }}><strong>Local Expertise: </strong><span style={{ fontWeight: 400 }}>We focus exclusively on hotels in Australia, allowing us
                                          to offer you an in-depth, local perspective on the best options
                                          available.</span></li>
                                  <li style={{ fontWeight: 400 }}><strong>Genuine Reviews: </strong><span style={{ fontWeight: 400 }}>Our reviews are based on real experiences, written by
                                          passionate travelers who share their honest and uncompromising
                                          perspective.</span></li>
                                  <li style={{ fontWeight: 400 }}><strong>Unique Selection: </strong><span style={{ fontWeight: 400 }}>We focus on hotels that stand out for their
                                          originality and character, guaranteeing you memorable and unusual stays.</span>
                                  </li>
                              </ul>
                              <h4><strong>Our Team</strong></h4>
                              <p><span style={{ fontWeight: 400 }}>bookrestort is comprised of a team of writers,
                                      photographers and travel enthusiasts who share a passion for exploring Australia’s most
                                      fascinating places. Each member of our team brings their expertise to provide you
                                      with in-depth and reliable reviews, while capturing the unique essence of each
                                      hotel.</span></p>
                              <h4><strong>Our Commitment</strong></h4>
                              <p><span style={{ fontWeight: 400 }}>We are committed to providing you with quality reviews,
                                      based on real-life experiences and thorough research. We believe in transparency and
                                      integrity, and strive to maintain a high level of professionalism in everything we
                                      do.</span></p>
                              <h4><strong>Contact Us</strong></h4>
                              <p><span style={{ fontWeight: 400 }}>Do you have any questions, suggestions or would you like
                                      to share your own hotel experience? We would love to hear from you! Contact us via
                                      our online form or send us an email to <a href="mailto:info@bookrestort.com">info@bookrestort.com</a> .</span>
                              </p>
                              <p><span style={{ fontWeight: 400 }}>Thank you for trusting bookrestort with your
                                      accommodation needs. We look forward to accompanying you on your next Dutch
                                      adventure!</span></p>
  
  
                          </div>
                      </div>
  
                  </div>
  
  
  
  
  
  
  
  
              </div>
          </section>
      </main>
       <Footer/>
   </>
  )
}

export default About