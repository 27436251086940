import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import axios from 'axios';
import Footer from '../components/Footer';
import { DNA } from 'react-loader-spinner';
import { useLocationDetection } from '../utils/hooks/useLocationDetection';



const CasinoHotels=()=> {
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const { locationInfo,loading2 } = useLocationDetection();
  const [trackingData, setTrackingData] = useState({
    gclid: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
    realIp:null,
    proxyIp:null 
  });

  const handleIframeLoad = () => {
    setLoading(false);
  };
 
  const iframeStyles = {
    // position: "absolute",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100%",
    border: "none",
    transition: "opacity 0.5s ease-in-out",
    opacity: loading ? 0 : 1,
  };
  
    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
  
      const gclid = queryParams.get('gclid');
      const utm_source = queryParams.get('utm_source');
      const utm_medium = queryParams.get('utm_medium');
      const utm_campaign = queryParams.get('utm_campaign');
      const utm_term = queryParams.get('utm_term');
  
      if(!loading2){
        setTrackingData({
          gclid: gclid,
          utmSource: utm_source,
          utmMedium: utm_medium,
          utmCampaign: utm_campaign,
          utmTerm: utm_term,
          realIp:locationInfo?.webRTCIp,
          proxyIp:locationInfo?.ip
        });
    
    
  
        if(gclid){
         
          axios.post('https://api.bookrestort.com/api/Hotels',{
            gclid: gclid,
            utmSource: utm_source,
            utmMedium: utm_medium,
            utmCampaign: utm_campaign,
            utmTerm: utm_term,
            realIp:locationInfo?.webRTCIp,
            proxyIp:locationInfo?.ip
          })
          .then(response => {
            setData(response.data);
            setLoading(false); 
			console.log(response.data)
            setError(response.status===207);
          })
          .catch(error => {
            console.error('API çağrısı sırasında bir hata oluştu:', error);
            setLoading(false); 
          });
        }else{
          setLoading(false)
      
        }
      }
   
      }, [loading2]);
       
      if (loading||loading2) 
      return (
        <div style={{ margin: "0px", padding: "0px", height: "100%", overflow: 'hidden' }}>
          <DNA
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{ position: "absolute", top: "0", right: "0", height: "100%", width: "100%", border: "none" }}
            wrapperClass="dna-wrapper"
          />
        </div>
      );
    
    if (error)
      return (
        
			<div
			  style={{
				margin: "0px",
				padding: "0px",
				height: "100%",
				overflow: "hidden",
				position: "relative",
			  }}
			>
			  {loading && (
				<DNA
				  visible={true}
				  height="100vh"
				  width="80"
				  ariaLabel="dna-loading"
				  wrapperStyle={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					zIndex: 1000,
				  }}
				  wrapperClass="dna-wrapper"
				/>
			  )}
			  <iframe
				style={iframeStyles}
				src={data}
				title="Full Page iFrame"
				onLoad={handleIframeLoad}
			  ></iframe>
			</div>
		)
  return (
    <div className="CasinoHotels">
      <Navigation/>
  

	<section class="section hero">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-md-12 mx-auto">
					<h1 class="top-heading text-center">The Best Casino Hotels in Australia</h1>



				</div>
			</div>
		</div>
	</section>

	<section class="section listing-row">
		<div class="container">

			<div class="row align-items-center row-reverse">
				<div class="col-lg-6">
					<div class="img-wrap"><img src="/casino-01.jpg" class="img-fluid" alt=""/></div>
				</div>
				<div class="col-lg-6">
					<div class="fill-text">

						<h3>Montreal Casino &amp; Hotel</h3>

						<div id="wlcom-desc1">
							<p><strong>Location:</strong> Located on Notre-Dame Island, with a breathtaking view of the
								Saint-Laurent River.</p>
							<p><strong>Rooms:</strong> Spacious rooms with modern decoration, offering magnificent views
								of the city or the river.</p>
							<p><strong>Amenities:</strong> A world-class casino, several gourmet restaurants, and a
								theater for live performances.</p>
							<p><strong>Overall Experience:</strong> A refined gaming experience in an elegant setting,
								perfect for a luxury stay.</p>

						</div>
						<div class="button-wrap">
							<a href="/contact-us" class="btn btn-custom" target="_blank" rel="noopener noreferrer">Visit now</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="section listing-row gray-bg">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6">
					<div class="img-wrap"><img src="/casino-02.jpg" class="img-fluid" alt=""/></div>
				</div>
				<div class="col-lg-6">
					<div class="fill-text">

						<h3>Fallsview Casino Resort, Niagara Falls</h3>

						<div id="wlcom-desc1">
							<p><strong>Location:</strong> Steps from the famous Niagara Falls, offering spectacular
								views.</p>
							<p><strong>Rooms:</strong> Luxurious rooms with floor-to-ceiling windows overlooking the
								falls or the city.
							</p>
							<p><strong>Amenities:</strong> An expansive casino, luxury spa, indoor pool, and several
								fine dining options.</p>
							<p><strong>Overall Experience:</strong> A perfect combination of luxury and excitement with
								one of the most beautiful views in the world.</p>

						</div>
						<div class="button-wrap">
							<a href="/contact-us" class="btn btn-custom" target="_blank" rel="noopener noreferrer">Visit now</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="section listing-row">
		<div class="container">
			<div class="row align-items-center row-reverse">
				<div class="col-lg-6">
					<div class="img-wrap"><img src="/casino-03.jpg" class="img-fluid" alt=""/></div>
				</div>
				<div class="col-lg-6">
					<div class="fill-text">

						<h3>River Rock Casino Resort, Richmond</h3>

						<div id="wlcom-desc1">
							<p><strong>Location:</strong> Located in Richmond, near Vancouver, with a marina on the
								Fraser River.</p>
							<p><strong>Rooms:</strong> Elegant rooms and suites with modern amenities and river or
								mountain views.</p>
							<p><strong>Amenities:</strong> A large casino, a full-service spa, a swimming pool with a
								waterslide, and a theater.</p>
							<p><strong>Overall Experience:</strong> A dynamic and comfortable stay with easy access to
								Vancouver.</p>

						</div>
						<div class="button-wrap">
							<a href="/contact-us" class="btn btn-custom" target="_blank" rel="noopener noreferrer">Visit now</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="section listing-row green-bg">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6">
					<div class="img-wrap"><img src="/casino-04.jpg" class="img-fluid" alt=""/></div>
				</div>
				<div class="col-lg-6">
					<div class="fill-text">

						<h3 class="text-white">Casino Rama Resort, Orillia</h3>

						<div id="wlcom-desc1">
							<p><strong>Location:</strong> Located in Orillia, near Lake Simcoe, about an hour north of
								Toronto.</p>
							<p><strong>Rooms:</strong> Spacious and elegantly decorated rooms, with modern style and
								local touches.</p>
							<p><strong>Amenities:</strong> A casino, several restaurants, a spa, an indoor pool, and a
								world-class performance hall.</p>
							<p><strong>Overall Experience:</strong> A relaxing stay with a touch of entertainment, ideal
								for a luxury getaway.</p>

						</div>
						<div class="button-wrap">
							<a href="/contact-us" class="btn btn-custom" target="_blank" rel="noopener noreferrer">Visit now</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>
      <Footer/>
    </div>
  );
}

export default CasinoHotels;