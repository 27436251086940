import React from 'react'

function Offer() {
  return (
<section class="section offer-sec gray-bg">
        <div class="container">
            <h2 class="sec-title">What we offer</h2>
            <div class="row">
                <div class="col-md-4">
                    <img src="/hotel.png" class="img-fluid" alt=""/>
                    <h3>Curated Selection of Unique Hotels</h3>
                    <p>bookrestort specializes in showcasing Australia's most unique and memorable properties, ensuring our
                        users have one-of-a-kind stay experiences, whether they're art hotels, modern capsules,
                        luxurious casinos or magical ice hotels.
                    </p>
                </div>
                <div class="col-md-4">
                    <img src="/scrutiny.png" class="img-fluid" alt=""/>
                    <h3>Authentic and Detailed Reviews</h3>
                    <p>Our reviews are based on real experiences and provide comprehensive details about location,
                        rooms, amenities, and overall experience. We provide complete transparency to help travelers
                        make informed choices.</p>
                </div>
                <div class="col-md-4">
                    <img src="/customer-focus.png" class="img-fluid" alt=""/>
                    <h3>100% Dutch Focus</h3>
                    <p>By focusing exclusively on hotels in Australia, we have an in-depth knowledge of the local market.
                        This allows us to provide accurate and reliable recommendations tailored to the tastes of
                        travelers exploring Australia.</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Offer