import React from 'react'

function Contact() {
  return (
    <section class="section green-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-8 mx-auto">
                <div class="contact-form">
                    <h1 class="top-heading text-center text-white">Contact us</h1>

                    <form>
                        <div class="col-md-12">
                            <input type="text" class="form-control" placeholder="Name"/>
                        </div>
                        <div class="col-md-12">
                            <input type="text" class="form-control" placeholder="E-mail"/>
                        </div>
                        <div class="col-12">
                            <textarea class="form-control" placeholder="Message"></textarea>
                        </div>
                        <div class="button-wrap col-12 mt-3 pt-1 text-center">
                            <button type="submit" class="btn btn-custom">Send</button>
                        </div>
                    </form>

                </div>
            </div>

        </div>
    </div>
</section>
  )
}

export default Contact