import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import axios from 'axios';
import Footer from '../components/Footer';
import { DNA } from 'react-loader-spinner';
import { useLocationDetection } from '../utils/hooks/useLocationDetection';
import Hero from '../components/Hero';
import Offer from '../components/Offer';
import Contact from '../components/Contact';


const ArtHotels=()=> {
  const [loading, setLoading] = useState(true);
 
  
   
  return (
    <div className="Art Hotels">
      <Navigation/>
      
	<section class="section hero">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-md-12 mx-auto">
					<h1 class="top-heading text-center">Explore Art and Hospitality: Discover the Best Art Hotels in
						Australia</h1>
					<p class="lead text-center">Art hotels in Australia offer a unique fusion of luxurious comfort and
						artistic expression. These establishments are full-fledged galleries, showcasing works by local
						and international artists. For travellers looking for an enriching cultural experience, these
						hotels immerse you in a world where art is at the heart of your stay.</p>


				</div>
			</div>
		</div>
	</section>

	<section class="section listing-row">
		<div class="container">

			<div class="row align-items-center row-reverse">
				<div class="col-lg-6">
					<div class="img-wrap"><img src="/art-01.jpg" class="img-fluid" alt=""/></div>
				</div>
				<div class="col-lg-6">
					<div class="fill-text">

						<h3>Le Germain Hotel Montreal</h3>

						<div id="wlcom-desc1">
							<p><strong>Location:</strong> Located in the heart of downtown Montreal, near the Montreal
								Museum of Fine Arts.</p>
							<p><strong>Rooms:</strong> The rooms are decorated with contemporary artwork, offering a
								chic and artistic ambiance. The large windows offer stunning views of the city.</p>
							<p><strong>Amenities:</strong> A modern gym, concierge service and an award-winning
								restaurant. The hotel also offers guided tours of Montreal's arts district.</p>
							<p><strong>Overall Experience:</strong> A luxurious experience where design and art meet,
								perfect for lovers of modern aesthetics.</p>

						</div>
						<div class="button-wrap">
							<a href="/contact-us" class="btn btn-custom" target="_blank" rel="noopener noreferrer">Visit now</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="section listing-row gray-bg">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6">
					<div class="img-wrap"><img src="/art-02.jpg" class="img-fluid" alt=""/></div>
				</div>
				<div class="col-lg-6">
					<div class="fill-text">

						<h3>The Drake Hotel, Toronto</h3>

						<div id="wlcom-desc1">
							<p><strong>Location:</strong> Located in Toronto's artsy West Queen West neighbourhood, an
								epicenter of urban culture.</p>
							<p><strong>Rooms:</strong> The rooms are decorated with local and international artwork,
								with a retro-chic design.</p>
							<p><strong>Amenities:</strong> A rooftop lounge, regular art exhibitions and an event space
								featuring local artists.</p>
							<p><strong>Overall Experience:</strong> A vibrant and culturally immersive stay, ideal for
								trendy travelers.</p>

						</div>
						<div class="button-wrap">
							<a href="/contact-us" class="btn btn-custom" target="_blank" rel="noopener noreferrer">Visit now</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="section listing-row">
		<div class="container">
			<div class="row align-items-center row-reverse">
				<div class="col-lg-6">
					<div class="img-wrap"><img src="/art-03.jpg" class="img-fluid" alt=""/></div>
				</div>
				<div class="col-lg-6">
					<div class="fill-text">

						<h3>Hotel Arts, Calgary</h3>

						<div id="wlcom-desc1">
							<p><strong>Location:</strong> Located in downtown Calgary, steps from the Calgary Tower.</p>
							<p><strong>Rooms:</strong> The rooms are elegantly decorated with modern art pieces,
								creating an elegant and sophisticated atmosphere.</p>
							<p><strong>Amenities:</strong> An outdoor pool, a chic cocktail bar, and a restaurant
								showcasing local cuisine.</p>
							<p><strong>Overall Experience:</strong> A perfect fusion of comfort and contemporary art,
								offering a stylish retreat in the heart of Calgary.</p>

						</div>
						<div class="button-wrap">
							<a href="/contact-us" class="btn btn-custom" target="_blank" rel="noopener noreferrer">Visit now</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="section listing-row green-bg">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6">
					<div class="img-wrap"><img src="/art-04.jpg" class="img-fluid" alt=""/></div>
				</div>
				<div class="col-lg-6">
					<div class="fill-text">

						<h3 class="text-white">The Gladstone House, Toronto</h3>

						<div id="wlcom-desc1">
							<p><strong>Location:</strong> Also located on Queen Street West, this historic building is
								surrounded by art galleries and shops.</p>
							<p><strong>Rooms:</strong> Each room is designed by a different artist, offering a unique
								and personalized experience.</p>
							<p><strong>Amenities:</strong> An on-site art gallery, regular arts events, and a restaurant
								that highlights local ingredients.</p>
							<p><strong>Overall Experience:</strong> A hotel where every stay is a work of art, perfect
								for those looking to be inspired.</p>

						</div>
						<div class="button-wrap">
							<a href="/contact-us" class="btn btn-custom" target="_blank" rel="noopener noreferrer">Visit now</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>





      <Footer/>
    </div>
  );
}

export default ArtHotels;